import service from '../../services/licenses.service';

export default {
  namespaced: true,

  state: {
    all: {},
  },

  actions: {
    getAll({ commit }) {
      commit('getAllRequest');

      return service.getAll().then(
        (licenses) => {
          const menuPlans = licenses.filter((l) => !l.code.includes('SUCURSALES'));
          const sucPlans = licenses.filter((l) => l.code.includes('SUCURSALES'));
          commit('getAllSuccess', { menu: menuPlans, sucursales: sucPlans });
        },
        (error) => commit('getAllFailure', error),
      );
    },
  },

  mutations: {
    // Get All
    getAllRequest(state) {
      state.all = { loading: true };
    },
    getAllSuccess(state, { menu, sucursales }) {
      state.all = { items: menu, sucursales, loading: false };
    },
    getAllFailure(state, error) {
      state.all = { error };
    },
  },
};
