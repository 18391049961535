<template>
  <div
    class="modal fade"
    id="transactionEditor"
    tabindex="-1"
    aria-labelledby="transactionEditorLabel"
    aria-hidden="true">
    <div class="modal-dialog" style="height: 100vh">
      <div class="modal-content overflow-auto h-100">
        <div class="modal-header d-block border-0">
          <h3 class="modal-title" id="transactionEditorLabel">{{ title }}</h3>
        </div>
        <div class="modal-body">
          <div class="customer">
            <div class="customer-info mb-5">
              <p class="fs-2">{{ customer?.company }}</p>
              <p class="fs-4 text-muted">{{ customer?.email }}</p>
            </div>
            <div class="customer-expiry mb-4">
              <p class="fs-5 text-muted">{{ expiration }}</p>
            </div>
          </div>
          <form class="mb-5">
            <label v-if="type !== 'AL'" class="form-label d-flex align-items-center mb-4" for=""
              ><span>Reactivar por</span
              ><input
                class="form-control demo-days px-4 mx-3"
                type="number"
                v-model="data.trial_days" /><span>Días</span></label
            >

            <small class="text-danger" v-if="type !== 'AL' && error && !data.trial_days"
              >No puede ser vacío</small
            >
            <label v-if="type !== 'AL'" class="form-label d-block mb-4" for=""
              >Motivo<textarea
                class="form-control mt-2"
                rows="4"
                v-model="data.comments"></textarea>
            </label>
            <label v-if="type === 'AL'" class="form-label d-block mb-4" for=""
              >Tipo de licencia
              <select
                class="form-control mt-2"
                :id="dropLicenses.name"
                :name="dropLicenses.name"
                autocomplete="off"
                v-model="data.license_id"
                @input="handleDropdown">
                <option value="" selected>{{ dropLicenses.placeholder }}</option>
                <option v-for="(option, i) in dropLicenses.options" :key="i" :value="option.value">
                  {{ option.name }}
                </option>
              </select>

              <small class="text-danger" v-if="error && !data.license_id"
                >Selecciona una opción</small
              >
            </label>
            <label v-if="type === 'AL'" class="form-label d-block mb-4" for=""
              >Periodo
              <select
                class="form-control mt-2"
                :id="dropPeriod.name"
                :name="dropPeriod.name"
                autocomplete="off"
                v-model="data.period"
                @input="handleDropdown">
                <option value="" selected>{{ dropPeriod.placeholder }}</option>
                <option v-for="(option, i) in dropPeriod.options" :key="i" :value="option.value">
                  {{ option.name }}
                </option>
              </select>

              <small class="text-danger" v-if="error && !data.payment_method"
                >No puede ser vacío</small
              >
            </label>
            <label
              v-if="type === 'AL' && licenseSelected.includes('Franquicias')"
              class="form-label d-block mb-4"
              for=""
              >Limite de sucursales<input
                class="form-control mt-2"
                type="number"
                v-model="data.sucursales_menu" />

              <small class="text-danger" v-if="error && !data.sucursales_menu"
                >No puede ser vacío</small
              >
              <small class="text-danger" v-if="error && data.sucursales_menu <= 0"
                >Debe ser mayor a cero</small
              >
            </label>
            <label v-if="type === 'AL'" class="form-label d-block mb-4" for=""
              >Pago realizado por
              <select
                class="form-control mt-2"
                :id="dropPayment.name"
                :name="dropPayment.name"
                autocomplete="off"
                v-model="data.payment_method"
                @input="handleDropdown">
                <option value="" selected>{{ dropPayment.placeholder }}</option>
                <option v-for="(option, i) in dropPayment.options" :key="i" :value="option.value">
                  {{ option.name }}
                </option>
              </select>

              <small class="text-danger" v-if="error && !data.period">No puede ser vacío</small>
            </label>
            <label v-if="type === 'AL'" class="form-label d-block mb-4" for=""
              >Referencia del pago<input
                class="form-control mt-2"
                type="text"
                v-model="data.payment_reference" />

              <small class="text-danger" v-if="error && !data.payment_reference"
                >No puede ser vacío</small
              >
            </label>
            <label v-if="type === 'AL'" class="form-label d-block mb-4" for=""
              >Notas y comentarios<textarea
                class="form-control mt-2"
                rows="4"
                v-model="data.comments"></textarea>
            </label>
          </form>

          <div class="modal-footer p-0 border-0">
            <div v-if="loading" class="m-auto">
              <div
                class="spinner-border text-primary"
                style="width: 5rem; height: 5rem"
                role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <button ref="close" type="button" class="invisible" data-bs-dismiss="modal"></button>
            </div>
            <div v-else class="d-flex w-100">
              <button
                type="button"
                class="btn btn-secondary flex-grow-1 me-3"
                data-bs-dismiss="modal"
                @click="resetEditor">
                Cancelar
              </button>
              <button type="button" class="btn btn-primary flex-grow-1" @click="newInvoice">
                {{ title }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'InvoiceEditorModal',
  mounted() {},
  data() {
    return {
      accountSubs: [],
      data: {
        license_id: '',
        trial_days: 0,
        payment_method: '',
        period: '',
        payment_reference: '',
        sucursales_menu: 1,
        comments: '',
        is_demo: '',
      },
      error: false,
      loading: false,
      dropPeriod: {
        name: 'period',
        placeholder: 'Selecciona el periodo',
        options: [
          {
            name: 'Mensual',
            value: 'Mensual',
          },
          {
            name: 'Anual',
            value: 'Anual',
          },
        ],
      },
      dropPayment: {
        name: 'payment_method',
        placeholder: 'Selecciona la forma de pago',
        options: [
          {
            name: 'Paypal',
            value: 'Paypal',
          },
          {
            name: 'Transferencia bancaria',
            value: 'Transferencia bancaria',
          },
          {
            name: 'Pago en oxxo',
            value: 'Pago en oxxo',
          },
          {
            name: 'Efectivo',
            value: 'Efectivo',
          },
          {
            name: 'Cortesía',
            value: 'Cortesía',
          },
          {
            name: 'Otro',
            value: 'Otro',
          },
        ],
      },
      dropLicenses: {
        name: 'payment_method',
        placeholder: 'Selecciona el tipo de licencia',
        options: this.licenseOptions,
      },
    };
  },
  props: {
    customer: Object,
    licenses: Array,
    status: String,
    type: String,
    licenseOptions: Array,
  },
  watch: {
    customer() {
      if (this.customer.id) {
        this.getAccountSubs(this.customer.id).then(({ data }) => {

          this.accountSubs = data;
        });
      }
    },
    licenses() {
      this.dropLicenses.options = this.licenseOptions;
    },
  },
  computed: {
    ...mapState({
      licensesList: (state) => state.licenses.all,
    }),
    title() {
      if (this.type === 'RD') {
        return 'Reactivar demo';
      }
      if (this.type === 'ED') {
        return 'Extender demo';
      }

      return 'Activar licencia';
    },
    expiration() {
      const expiry = this.$moment(this.customer?.expiry).format('DD/MM/YY');
      const relative = this.$moment(this.customer?.expiry).locale('es').fromNow();
      const state = this.status === 'activo' ? 'Vence el' : 'Venció el';

      return `${state} ${expiry} (${relative})`;
    },
    licenseSelected() {
      const license = this.licenses.find((l) => this.data.license_id === l.name);
      return license?.name || '';
    },
  },
  methods: {
    ...mapActions('invoices', {
      extendDemo: 'extendDemo',
      // createInvoice: 'create',
      getAccountSubs: 'getAccountSubs',
      activateLicenses: 'activateLicenses',
      newSubscription: 'newSubscription',
    }),
    ...mapActions('customers', {
      getOneCustomer: 'getOne',
    }),
    newInvoice() {
      this.$log('New Invoice');
      if (this.type === 'AL') {
        this.activarLicencia();
      } else {
        this.activarDemo();
      }
    },
    activarDemo() {
      this.error = false;
      // eslint-disable-next-line
      const { id, sucursales_limit } = this.customer;
      // eslint-disable-next-line
      const { trial_days, comments } = this.data;

      const data = { trial_days };

      if (comments) data.comments = comments;

      // eslint-disable-next-line
      if (trial_days || comments) {
        this.loading = true;

        const menuSubs = this.accountSubs.find((s) => !s.code.includes('SUCURSALES'));
        const sucSubs = this.accountSubs.find((s) => s.code.includes('SUCURSALES'));

        data.plan_code = menuSubs.code;

        const expired = this.$moment(this.customer.expiry).unix() - this.$moment().unix() < 0;

        const newExpiry = expired
          ? this.$moment().add(trial_days, 'days')
          : this.$moment(this.customer.expiry).add(trial_days, 'days');

        this.extendDemo({ id, subsId: menuSubs.id, data }).then(() => {
          this.customer.status = 'activo';
          this.customer.expiry = this.$moment(newExpiry).format('YYYY-MM-DD HH:mm:ss');
          this.$refs.close.click();
          this.loading = false;
          this.resetEditor();
        });
        if (sucSubs && menuSubs.code.includes('FRANCHISES')) {
          data.plan_code = sucSubs.code;

          this.extendDemo({ id, subsId: sucSubs.id, data });
        }
      } else {
        this.error = true;
      }
    },
    activarLicencia() {
      this.error = false;
      // eslint-disable-next-line
      const {id, currency} = this.customer; // TODO if reload page of customer details, will not load customer
      // eslint-disable-next-line
      const {payment_method, payment_reference, comments} = this.data;

      const interval = this.data.period === 'Mensual' ? 'monthly' : 'yearly';

      // eslint-disable-next-line
      const { code } =
        this.licensesList.items.find(
          (l) => l.interval === interval && l.name === this.data.license_id && l.currency === currency,
        ) || {};

        console.log('this.customer', this.customer);
        console.log('this.licensesList.items', this.licensesList.items);
        console.log('code', code);

      const data = {
        plan_code: code,
        payment_method,
        payment_reference,
      };

      // Check props
      const props = ['plan_code', 'payment_method', 'payment_reference'];

      props.forEach((p) => {
        // eslint-disable-next-line
        if (!data[p]) this.error = true;
      });

      data.comments = comments || 'Sin comentarios';
      if (data.plan_code.includes('FRANCHISES')) {
        data.features = [{ code: `MENU-SUCURSALES-${currency}`, quantity: this.data.sucursales_menu }];
      }

      const menuSubs = this.accountSubs.find((s) => !s.code.includes('SUCURSALES'));
      // const sucSubs = this.accountSubs.find((s) => s.code.includes('SUCURSALES'));

      if (!this.error) {
        this.loading = true;
        this.activateLicenses({ id, subsId: menuSubs.id, data }).then(() => {
          if (data.plan_code.includes('FRANCHISES')) {
            // const sucPlan = this.licensesList.sucursales.find(
            //   (s) => s.name.includes(this.data.sucursales_menu) && s.interval === interval,
            // );
            // if (sucSubs) {
            //   this.activateLicenses({ id, subsId: sucSubs.id, data });
            // } else {
            //   // If no exist sucSubs create new one
            //   this.newSubscription({ id, data: { plan_code: sucPlan.code, parent: menuSubs.id } });
            // }
          }

          this.getOneCustomer(id).then(() => {
            this.$refs.close.click();
            this.loading = false;
            this.resetEditor();
          });
        });
      }
    },
    handleDropdown(e) {
      this.$emit('dropdown', this.name, e.target.value);
    },
    resetEditor() {
      this.data = {
        license_id: '',
        trial_days: 0,
        period: '',
        payment_method: '',
        payment_reference: '',
        sucursales_menu: 1,
        comments: '',
        is_demo: '',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  &-dialog {
    max-width: 400px;
    margin: 0;
    margin-left: auto;
  }
}
.demo-days {
  width: 50px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
