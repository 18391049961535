<template>
  <div class="details overflow-hidden w-100 p-5 position-relative">
    <button class="btn btn-secondary btn-sm" @click="$emit('go-back')">
      <i class="icon-arrow_back"></i> Regresar
    </button>
    <div class="details-content mt-5" style="height: 90%">
      <div class="details-header mb-5">
        <div class="d-flex justify-content-between border-bottom mb-3">
          <div class="details-title">
            <h2>{{ company }}</h2>
            <p>{{ business_kind }}</p>
          </div>
          <div class="details-controls">
            <button
              class="btn btn-primary btn-sm ms-3"
              data-bs-toggle="modal"
              data-bs-target="#transactionEditor"
              @click="$emit('transaction', 'AL')">
              Activar licencia
            </button>
            <button
              v-if="status === 'activo' && demo"
              class="btn btn-secondary btn-sm ms-3"
              data-bs-toggle="modal"
              data-bs-target="#transactionEditor"
              @click="$emit('transaction', 'ED')">
              Extender demo
            </button>
            <button
              v-else-if="demo"
              class="btn btn-secondary btn-sm ms-3"
              data-bs-toggle="modal"
              data-bs-target="#transactionEditor"
              @click="$emit('transaction', 'RD')">
              Reactivar demo
            </button>
            <button
              class="btn btn-secondary btn-sm ms-3"
              data-bs-toggle="modal"
              data-bs-target="#customerEditor">
              Editar información
            </button>
            <button
              class="btn btn-secondary btn-sm ms-3"
              data-bs-toggle="modal"
              data-bs-target="#passwordEditor">
              Cambiar contraseña
            </button>
            <button
              v-if="section === 'licenses' && invoices.items.length > 1"
              class="btn border border-3 border-danger text-danger btn-sm ms-3"
              @click="visibleModal2 = true">
              Eliminar licencia más reciente
            </button>
          </div>
        </div>
        <p ref="signal" class="d-flex align-items-center" :class="signalStatus">
          <span
            ref="circleSignal"
            class="d-inline-block rounded-circle circle-signal p-3 me-2"></span
          >{{ signal }}
        </p>
      </div>
      <div class="details-wrapper" style="height: 85%">
        <div class="details-switch text-center mb-5">
          <button
            class="btn btn-sec"
            :class="section === 'general' ? 'btn-active' : ''"
            @click="section = 'general'">
            Generales
          </button>
          <button
            class="btn btn-sec"
            :class="section === 'licenses' ? 'btn-active' : ''"
            @click="section = 'licenses'">
            Demos y licencias
          </button>
        </div>
        <div v-if="section === 'general'" class="details-info d-flex justify-content-center">
          <div class="details-general-info border-end">
            <p class="border-bottom fs-3 py-4">Datos generales</p>
            <div class="d-flex">
              <div class="me-5">
                <p class="mb-4">Nombre del restaurante</p>
                <p class="mb-4">Nombre del contacto</p>
                <p class="mb-4">Giro del negocio</p>
                <p class="mb-4">Teléfono</p>
                <p class="mb-4">Correo electrónico</p>
                <p class="mb-4">Antigüedad</p>
              </div>
              <div class="text-muted me-5 pe-5">
                <p class="mb-4">{{ company }}</p>
                <p class="mb-4">{{ first_name + ' ' + last_name }}</p>
                <p class="mb-4">{{ business_kind }}</p>
                <p class="mb-4">{{ phone }}</p>
                <p class="mb-4">{{ email }}</p>
                <p class="mb-4">{{ $moment(created).locale('es').fromNow() }}</p>
              </div>
            </div>
          </div>

          <div class="details-usage-info">
            <p class="border-bottom fs-3 py-4" style="padding-left: 60px">Datos de uso</p>
            <div class="details-info d-flex">
              <div class="mx-5 ps-5">
                <p class="mb-4"># Productos dados de alta</p>
                <p class="mb-4"># Sucursales creadas</p>
                <p class="mb-4">Fecha y hora de registro</p>
                <p class="mb-4">Fecha y hora de ultimo acceso</p>
                <p class="mb-4">Fecha y hora de expiración</p>
                <p class="mb-4">Hora actual en {{ country }}</p>
              </div>
              <div class="text-muted">
                <p class="mb-4">{{ products }}</p>
                <p class="mb-4">{{ sucursales }}</p>
                <p class="mb-4">{{ timeFormat(created) }}</p>
                <p class="mb-4">{{ timeFormat(last_login) }}</p>
                <p class="mb-4">{{ timeFormat(expiry) }}</p>
                <p class="mb-4">{{ clock }}</p>
              </div>
            </div>
          </div>
        </div>
        <button
          v-if="section === 'general'"
          class="btn border border-3 border-danger text-danger btn-sm mx-auto d-block mt-5"
          @click="
            accountToDelete = id;
            visibleModal = true;
          ">
          Eliminar cuenta
        </button>
        <div
          v-else-if="section === 'licenses'"
          class="details-licenses"
          style="height: 90%; overflow: scroll">
          <table class="table table-striped table-hover table-bordered">
            <thead>
              <tr>
                <th
                  scope="col"
                  v-for="(col, i) in cols"
                  class="text-center"
                  style="cursor: pointer"
                  @click="sort(col.prop)"
                  :key="i"
                  :style="col.style">
                  <span>{{ col.header }}</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="row-item"
                v-for="(item, i) in items"
                :key="i"
                :class="i === 0 && !sortBy ? 'position-relative' : ''">
                <td
                  v-for="(col, i) in cols"
                  :class="item[col.prop] === 'N/A' ? 'text-center' : ''"
                  :key="i"
                  :style="col.style">
                  <span>{{ item[col.prop] }}</span>
                </td>
                <!-- <div
                  v-if="i === 0 && !sortBy && !expired"
                  class="delete-row position-absolute bg-danger py-2 top-0 start-0"
                  @click="
                    itemToDelete = item.id;
                    visibleModal = true;
                  ">
                  <span class="material-symbols-outlined text-light"> delete </span>
                </div> -->
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div
      class="position-absolute top-50 start-50 translate-middle shadow"
      :class="visibleModal ? 'd-block' : 'd-none'">
      <div class="confirm-deleting p-5 bg-light rounded">
        <p class="mb-4">Estas seguro que quieres eliminar la cuenta de {{ first_name }}</p>
        <label class="mb-4" for="password">
          <input
            class="form-control mb-2"
            type="password"
            name="password"
            placeholder="************"
            v-model="dangerPass" />
          <small v-if="error" class="text-danger">{{ errMsg }}</small>
        </label>
        <div class="d-flex justify-content-end">
          <button
            class="btn btn-secondary me-3"
            @click="
              error = false;
              dangerPass = '';
              accountToDelete = '';
              visibleModal = false;
            ">
            Cancelar
          </button>
          <button class="btn btn-danger text-light" @click="deleteAccount">Eliminar</button>
        </div>
      </div>
    </div>

    <div
      class="position-absolute top-50 start-50 translate-middle shadow"
      :class="visibleModal2 ? 'd-block' : 'd-none'">
      <div class="confirm-deleting p-5 bg-light rounded">
        <p class="mb-4">
          Estas seguro que quieres eliminar la licencia más reciente de {{ first_name }}
        </p>
        <label class="mb-4" for="password">
          <input
            class="form-control mb-2"
            type="password"
            name="password"
            placeholder="************"
            v-model="dangerPass" />
          <small v-if="error" class="text-danger">{{ errMsg }}</small>
        </label>
        <div class="d-flex justify-content-end">
          <button
            class="btn btn-secondary me-3"
            @click="
              error = false;
              dangerPass = '';
              accountToDelete = '';
              visibleModal2 = false;
            ">
            Cancelar
          </button>
          <button class="btn btn-danger text-light" @click="prevSubscriptionPlan">
            Eliminar licencia
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import moment from 'moment-timezone';

export default {
  name: 'CustomerDetails',
  created() {},
  mounted() {
    this.signal = this.customerStatus;
    this.getAllInvoices(this.id).then(() => {
      this.$log('Invoices', this.invoices);
    });

    this.startTime();
  },
  data() {
    return {
      section: 'general',
      signal: '',
      sortBy: '',
      error: false,
      dangerPass: '',
      errMsg: '',
      removePass: '12345678',
      itemToDelete: '',
      visibleModal: false,
      visibleModal2: false,
      sortReverse: false,
      clock: '0000-00-00 00:00:00',
      cols: [
        {
          header: 'Tipo de licencia',
          prop: 'name',
          style: 'width: 200px; min-width: 200px; text-align: center',
        },
        {
          header: 'Nombre de licencia',
          prop: 'plan',
          style: 'width: 200px; min-width: 200px; text-align: center',
        },
        {
          header: 'Periodo',
          prop: 'interval',
          style: 'width: 100px; min-width: 100px; text-align: center',
        },
        // {
        //   header: '# Sucursales',
        //   prop: 'amount_sucursales',
        //   style: 'width: 120px; min-width: 120px; text-align: center',
        // },
        {
          header: 'Fecha de activación',
          prop: 'start_date',
          style: 'width: 200px; min-width: 200px; text-align: center',
        },
        {
          header: 'Fecha de expiración',
          prop: 'expiry_date',
          style: 'width: 180px; min-width: 180px; text-align: center',
        },
        {
          header: 'Forma de pago',
          prop: 'payment_method',
          style: 'width: 200px; min-width: 200px; text-align: center',
        },
        {
          header: 'Referencia',
          prop: 'payment_reference',
          style: 'width: 150px; min-width: 150px; text-align: center',
        },
        {
          header: 'Descripción',
          prop: 'description',
          style: 'width: 350px; min-width: 350px',
        },
        {
          header: 'Comentarios',
          prop: 'comments',
          style: 'width: 350px; min-width: 350px',
        },
      ],
    };
  },
  props: {
    id: String,
    first_name: String,
    last_name: String,
    company: String,
    business_kind: String,
    country: String,
    timezone: String,
    phone: String,
    email: String,
    license: String,
    sucursales: Number,
    products: Number,
    expiry: String,
    demo: Boolean,
    last_login: String,
    created: String,
    status: String,
  },
  watch: {
    expiry() {
      this.changeSignal();
    },
  },
  computed: {
    ...mapState({
      invoices: (state) => state.invoices.all,
    }),
    // country() {
    //   const countries = [
    //     {code:'CR', name: 'Costa Rica', prefix: '+506'},
    //     {code:'BZ', name: 'Belize', prefix: '+501'},
    //     {code:'SV', name: 'El Salvador', prefix: '+503'},
    //     {code:'GT', name: 'Guatemala', prefix: '+502'},
    //     {code:'HN', name: 'Honduras', prefix: '+504'},
    //     {code:'MX', name: 'México', prefix: '+52'},
    //     {code:'NI', name: 'Nicaragua', prefix: '+505'},
    //     {code:'PA', name: 'Panama', prefix: '+507'},
    //     {code:'AR', name: 'Argentina', prefix: '+54'},
    //     {code:'BO', name: 'Bolivia', prefix: '+591'},
    //     {code:'BR', name: 'Brazil', prefix: '+55'},
    //     {code:'CL', name: 'Chile', prefix: '+56'},
    //     {code:'CO', name: 'Colombia', prefix: '+57'},
    //     {code:'EC', name: 'Ecuador', prefix: '+593'},
    //     {code:'GY', name: 'Guyana', prefix: '+592'},
    //     {code:'PY', name: 'Paraguay', prefix: '+595'},
    //     {code:'PE', name: 'Peru', prefix: '+51'},
    //     {code:'PR', name: 'Puerto Rico', prefix: '+1'},
    //     {code:'SR', name: 'Suriname', prefix: '+597'},
    //     {code:'UY', name: 'Uruguay', prefix: '+598'},
    //     {code:'VE', name: 'Venezuela', prefix: '+58'}];

    //   const [prefix] = this.phone.split(' ');

    //   const country = countries.find((c) => c.prefix === prefix);

    //   if (country) return country.name;

    //   return ''
    // },
    expired() {
      return this.$moment(this.expiry).format() < this.$moment().format();
    },
    items() {
      if (!this.invoices.loading) {
        const list = this.invoices.items.map((item, i) => {
          const name = `${item.demo ? 'Demo' : 'Licencia'} ${item.product}`;
          const created = this.$moment(item.created).format('DD-MM-YYYY HH:mm:ss');
          const intervalDays = this.$moment(item.expires_at).diff(
            this.$moment(item.start_at),
            'days',
          );

          let interval = 'N/A';

          if (
            !this.invoices.items[i - 1] ||
            (this.invoices.items[i - 1] &&
              this.invoices.items[i].expires_at !== this.invoices.items[i - 1].expires_at)
          ) {
            interval =
              // eslint-disable-next-line
              intervalDays >= 365 ? '1 Año' : intervalDays >= 30 ? '1 Mes' : `${intervalDays} Dias`;
          }

          const start = this.$moment(item.start_at).format('DD-MM-YYYY');
          const expiry = this.$moment(item.expires_at).format('DD-MM-YYYY');
          const method = item.payment_method ? item.payment_method : 'N/A';
          const reference = item.payment_reference ? item.payment_reference : 'N/A';
          const comments = item.comments ? item.comments : 'N/A';

          return {
            ...item,
            name,
            interval,
            payment_method: method,
            payment_reference: reference,
            comments,
            created,
            start_date: start,
            expiry_date: expiry,
          };
        });

        if (typeof list[0][this.sortBy] === 'string') {
          return list.sort((a, b) => {
            if (this.sortReverse) {
              return b[this.sortBy].localeCompare(a[this.sortBy]);
            }

            return a[this.sortBy].localeCompare(b[this.sortBy]);
          });
        }

        if (typeof list[0][this.sortBy] === 'number') {
          return list.sort((a, b) => {
            if (this.sortReverse) {
              return b[this.sortBy] - a[this.sortBy];
            }
            return a[this.sortBy] - b[this.sortBy];
          });
        }
        return list;
      }
      return [];
    },
    signalStatus() {
      let t = this.demo ? 'd' : 'c';
      const s = this.status[0];

      if (this.license === 'Freemium') t = 'f';

      return `signal-${t}${s}`;
    },
    customerStatus() {
      const type = this.demo ? 'Demo' : 'Cliente';
      if (this.license === 'Freemium') return 'Freemium';

      return `${type} ${this.status}`;
    },
  },
  methods: {
    ...mapActions('customers', {
      getOneCustomer: 'getOne',
      deleteCustomer: 'remove',
    }),
    ...mapActions('invoices', {
      revertSubscription: 'revertSubscription',
      getAccountSubs: 'getAccountSubs',

      getAllInvoices: 'getAll',
    }),
    deleteAccount() {
      if (this.dangerPass === this.removePass) {
        this.deleteCustomer(this.accountToDelete).then(() => {
          this.$emit('go-back');
        });
        this.visibleModal = false;
        this.error = false;
        this.dangerPass = '';
      } else {
        this.error = true;
        if (this.dangerPass) {
          this.errMsg = 'La contraseña no coincide';
        } else {
          this.errMsg = 'Introduce la contraseña para autorizar el proceso';
        }
      }
    },
    prevSubscriptionPlan() {
      if (this.id && this.dangerPass === this.removePass) {
        this.getAccountSubs(this.id).then(({ data }) => {
          const menuSubs = data.find((s) => !s.code.includes('SUCURSALES'));

          this.revertSubscription({ id: this.id, subsId: menuSubs.id }).then(() =>
            this.getAllInvoices(this.id),
          );
        });

        this.visibleModal2 = false;
        this.error = false;
        this.dangerPass = '';
      } else {
        this.error = true;
        if (this.dangerPass) {
          this.errMsg = 'La contraseña no coincide';
        } else {
          this.errMsg = 'Introduce la contraseña para autorizar el proceso';
        }
      }
    },
    changeSignal() {
      let newSignal;
      const type = this.demo ? 'Demo' : 'Cliente';

      const classList = this.$refs.signal.classList.value.split(' ');

      if (this.expired) {
        newSignal = this.demo ? 'signal-di' : 'signal-ci';
      } else {
        newSignal = this.demo ? 'signal-da' : 'signal-ca';
      }

      const newClassList = classList.filter((c) => !c.includes('signal')).join(' ');

      this.$refs.signal.classList.value = `${newClassList} ${newSignal}`;

      this.signal = `${type} ${this.expired ? 'inactivo' : 'activo'}`;
    },
    timeFormat(date) {
      const dateTime = this.$moment(date).format('DD/MM/YY, HH:mm:ss');
      const relativeTime = this.$moment(date).locale('es').fromNow();

      return `${dateTime} (${relativeTime})`;
    },
    sort(prop) {
      this.sortBy = prop;
      this.sortReverse = !this.sortReverse;
    },
    /** Clock custom timezone */
    startTime() {
      this.clock = moment.tz(this.timezone).format('HH:mm:ss');

      setTimeout(this.startTime, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.delete-row {
  display: none;
  cursor: pointer;
}
.row-item:hover .delete-row {
  display: block;
}
.details {
  &-header {
    .signal-da {
      color: #2c88d9;
      font-size: 18px;
      .circle-signal {
        background: #96c3ec;
        border: 2px solid #2c88d9;
      }
    }
    .signal-di {
      color: #f77e25;
      .circle-signal {
        background: #fbbe92;
        border: 2px solid #f77e25;
      }
    }
    .signal-ca {
      color: #207868;
      .circle-signal {
        background: #90bcb3;
        border: 2px solid #207868;
      }
    }
    .signal-ci {
      color: #d3455b;
      .circle-signal {
        background: #e9a2ad;
        border: 2px solid #d3455b;
      }
    }
    .signal-fi,
    .signal-fa {
      color: #9492ff;
      .circle-signal {
        background: #dadaff;
        border: 2px solid #9492ff;
      }
    }
  }
}
.btn {
  &-sec {
    &:hover,
    &:focus,
    &:active {
      border-left: 0;
      border-right: 0;
      border-top: 0;
    }
  }
  &-active {
    color: $primary-light;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    border-bottom: solid 2px $primary-light;
  }
}
</style>
