<template>
  <div class="login h-100">
    <div class="login-form position-absolute top-50 start-50 translate-middle col-4">
      <div class="mf-logo text-center mb-5">
        <img src="@/assets/images/mindfood-solutions.png" alt="mindfood logo" />
      </div>
      <form @submit.prevent="handleSubmit">
        <div class="form-group mb-3 w-100">
          <label for="password" class="form-label w-100"
            ><span class="d-block ps-3 mb-1">Contraseña</span
            ><input
              id="password"
              name="password"
              placeholder="****************"
              type="password"
              v-model="password"
              required
              class="form-control"
          /></label>
        </div>
        <button type="submit" class="btn btn-primary fw-bolder w-100 my-3">Iniciar Sesión</button>
        <div v-if="submitted" class="alert alert-danger" role="alert">
          {{ loginError }}
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'Login',
  mounted() {
    if (this.account.status.loggedIn) this.$router.push('/customers/freemium');
  },
  data() {
    return {
      password: '',
      loginError: '',
      submitted: false,
    };
  },
  computed: {
    ...mapState({ account: (state) => state.account }),
  },
  props: {},
  methods: {
    ...mapActions('account', ['login']),
    handleSubmit() {
      const { password } = this;

      if (password) {
        this.login({ password }).then((loggedIn) => {
          if (loggedIn) {
            this.$router.push('/customers/freemium');
          } else {
            this.loginError = 'La contraseña es incorrecta, intenta de nuevo.';
            this.submitted = true;
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  background: hsla(260, 82%, 47%, 1);
  background: linear-gradient(300deg, hsla(260, 82%, 47%, 1) 0%, hsla(241, 100%, 79%, 1) 100%);
  background: -moz-linear-gradient(300deg, hsla(260, 82%, 47%, 1) 0%, hsla(241, 100%, 79%, 1) 100%);
  background: -webkit-linear-gradient(
    300deg,
    hsla(260, 82%, 47%, 1) 0%,
    hsla(241, 100%, 79%, 1) 100%
  );
  &-form {
    padding: 50px;
    background: #fff;
    border-radius: 10px;
  }
}
</style>
