<template>
  <div
    class="modal fade"
    id="passwordEditor"
    tabindex="-1"
    aria-labelledby="customerEditorLabel"
    aria-hidden="true">
    <div class="modal-dialog h-100">
      <div class="modal-content h-100">
        <div class="modal-header d-block border-0">
          <h3 class="modal-title" id="customerEditorLabel">Restablecer contraseña</h3>
          <p class="text-muted">{{ customer?.email }}</p>
        </div>
        <div class="modal-body">
          <form class="mb-5 pb-5">
            <label class="form-label d-block mb-4" for=""
              >Nueva contraseña<input
                class="form-control mt-2"
                type="password"
                v-model="data.newPassword" />
              <small class="text-danger" v-if="error && !data.newPassword"
                >No puede ser vacío</small
              >
              <small class="text-danger" v-else-if="error && data.newPassword.length < 8"
                >La contraseña debe ser de 8 o más caracteres</small
              >
            </label>
            <label class="form-label d-block mb-4" for=""
              >Confirmar contraseña<input
                class="form-control mt-2"
                type="password"
                v-model="data.matchPassword" />
              <small class="text-danger" v-if="error && !data.matchPassword"
                >No puede ser vacío</small
              >
              <small class="text-danger" v-else-if="error && !matchPass"
                >La contraseña no coincide</small
              >
            </label>
          </form>

          <div class="modal-footer p-0 border-0">
            <div v-if="loading" class="position-absolute start-50 translate-middle">
              <div
                class="spinner-border text-primary"
                style="width: 5rem; height: 5rem"
                role="status">
                <span class="visually-hidden">Loading...</span>
              </div>

              <button ref="close" type="button" class="invisible" data-bs-dismiss="modal"></button>
            </div>
            <div v-else class="d-flex w-100">
              <button
                type="button"
                class="btn btn-secondary flex-grow-1 me-3"
                data-bs-dismiss="modal">
                Cancelar
              </button>
              <button type="button" class="btn btn-primary flex-grow-1" @click="validate">
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'PasswordEditorModal',
  created() {},
  data() {
    return {
      data: {
        newPassword: '',
        matchPassword: null,
      },
      error: false,
      loading: false,
    };
  },
  props: {
    customer: Object,
  },
  computed: {
    matchPass() {
      return this.data.newPassword === this.data.matchPassword;
    },
  },
  methods: {
    ...mapActions('customers', ['changePassword']),
    validate() {
      const {
        customer: { id },
        data,
      } = this;

      this.error = !data.newPassword || !this.matchPass || data.newPassword.length < 8;

      if (!this.error) {
        this.loading = true;
        this.changePassword({ id, data: { new_password: data.newPassword } }).then(() => {
          this.$refs.close.click();
          this.loading = false;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  &-dialog {
    max-width: 400px;
    margin: 0;
    margin-left: auto;
  }
}
</style>
