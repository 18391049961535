import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../views/Login.vue';
import Customers from '../views/Customers.vue';

Vue.use(VueRouter);

const routes = [
  // {
  //   path: '/',
  //   redirect: '/login',
  // },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/customers',
    name: 'Customers',
    component: Customers,
    alias: [
      '/customers/freemium',
      '/customers/demo-active',
      '/customers/demo-inactive',
      '/customers/active',
      '/customers/inactive',
    ],
  },
  // otherwise redirect to root
  { path: '*', redirect: '/customers' },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// eslint-disable-next-line
router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('account');

  if (authRequired && !loggedIn) {
    return next('/login');
  }

  next();
});

export default router;
