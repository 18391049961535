<template>
  <div
    class="modal fade"
    id="customerEditor"
    tabindex="-1"
    aria-labelledby="customerEditorLabel"
    aria-hidden="true">
    <div class="modal-dialog h-100">
      <div class="modal-content h-100">
        <div class="modal-header d-block border-0">
          <h3 class="modal-title" id="customerEditorLabel">Editar datos</h3>
          <p class="text-muted">{{ customer?.email }}</p>
        </div>
        <div class="modal-body">
          <form class="mb-5 pb-5">
            <label class="form-label d-block mb-4" for=""
              >Nombre del restaurante<input
                class="form-control mt-2"
                type="text"
                v-model="data.company" />
              <small class="text-danger" v-if="error && !data.company">No puede ser vacío</small>
            </label>
            <label class="form-label d-block mb-4" for=""
              >Nombre<input class="form-control mt-2" type="text" v-model="data.first_name" />
              <small class="text-danger" v-if="error && !data.first_name">No puede ser vacío</small>
            </label>

            <label class="form-label d-block mb-4" for=""
              >Apellido<input class="form-control mt-2" type="text" v-model="data.last_name" />
              <small class="text-danger" v-if="error && !data.last_name">No puede ser vacío</small>
            </label>
            <label class="form-label d-block mb-4" for=""
              >Giro del negocio
              <select class="form-control mt-2" autocomplete="off" v-model="data.business_kind">
                <option value="" disabled selected>{{ dropBusiness.placeholder }}</option>
                <option
                  v-for="({ name, value }, i) in dropBusiness.options"
                  :key="i"
                  :value="value">
                  {{ name }}
                </option></select
              ><input
                v-if="(noMatch && !data.business_kind) || data.business_kind === 'Otro'"
                class="form-control mt-2"
                type="text"
                v-model="newBusiness_kind" />
              <small class="text-danger" v-if="error && !data.business_kind"
                >No puede ser vacío</small
              >
            </label>
            <label class="form-label d-block mb-4" for=""
              >Teléfono<input class="form-control mt-2" type="tel" v-model="data.phone" />
              <small class="text-danger" v-if="error && !data.phone">No puede ser vacío</small>
            </label>
          </form>

          <div class="modal-footer p-0 border-0">
            <div v-if="loading" class="position-absolute start-50 translate-middle">
              <div
                class="spinner-border text-primary"
                style="width: 5rem; height: 5rem"
                role="status">
                <span class="visually-hidden">Loading...</span>
              </div>

              <button ref="close" type="button" class="invisible" data-bs-dismiss="modal"></button>
            </div>
            <div v-else class="d-flex w-100">
              <button
                type="button"
                class="btn btn-secondary flex-grow-1 me-3"
                data-bs-dismiss="modal">
                Cancelar
              </button>
              <button type="button" class="btn btn-primary flex-grow-1" @click="validate">
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'CustomerEditorModal',
  created() {},
  data() {
    return {
      data: {
        first_name: this.customer?.first_name,
        last_name: this.customer?.last_name,
        phone: this.customer?.phone,
        company: this.customer?.company,
        business_kind: this.customer?.business_kind,
      },
      newBusiness_kind: '',
      error: false,
      loading: false,
      dropBusiness: {
        name: 'business_kind',
        placeholder: 'Selecciona el tipo de giro',
        options: [
          {
            name: 'Pizzeria',
            value: 'Pizzeria',
          },
          {
            name: 'Mariscos',
            value: 'Mariscos',
          },
          {
            name: 'Comida mexicana',
            value: 'Comida mexicana',
          },
          {
            name: 'Comida asiática',
            value: 'Comida asiática',
          },
          {
            name: 'Restaurante italiano',
            value: 'Restaurante italiano',
          },
          {
            name: 'Alitas',
            value: 'Alitas',
          },
          {
            name: 'Bar',
            value: 'Bar',
          },
          {
            name: 'Antro',
            value: 'Antro',
          },
          {
            name: 'Antojitos',
            value: 'Antojitos',
          },
          {
            name: 'Botanero',
            value: 'Botanero',
          },
          {
            name: 'Cafeteria',
            value: 'Cafeteria',
          },
          {
            name: 'Pub',
            value: 'Pub',
          },
          {
            name: 'Cervecería',
            value: 'Cervecería',
          },
          {
            name: 'Otro',
            value: 'Otro',
          },
        ],
      },
    };
  },
  props: {
    customer: Object,
  },
  watch: {
    customer(v) {
      this.data = {
        first_name: v.first_name,
        last_name: v.last_name,
        phone: v.phone,
        company: v.company,
        business_kind: v.business_kind,
      };

      const businessMatch = !this.dropBusiness.options.filter(
        (item) => item.value === this.data.business_kind,
      ).length;

      if (businessMatch) {
        this.newBusiness_kind = v.business_kind;
        this.data.business_kind = 'Otro';
      }
    },
  },
  computed: {
    noMatch() {
      return !!this.dropBusiness.options.filter((item) => item.value === this.data.business_kind)
        .length;
    },
  },
  methods: {
    ...mapActions('customers', {
      updateCustomer: 'update',
    }),
    validate() {
      const {
        customer: { id },
        data,
      } = this;

      data.phone = data.phone.toString();

      if (this.newBusiness_kind && data.business_kind === 'Otro')
        data.business_kind = this.newBusiness_kind;

      Object.entries(this.data).forEach((field) => {
        if (!field[1]) this.error = true;
      });

      if (!this.error) {
        this.loading = true;
        this.updateCustomer({ id, data }).then(() => {
          this.$refs.close.click();
          this.loading = false;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  &-dialog {
    max-width: 400px;
    margin: 0;
    margin-left: auto;
  }
}
</style>
