import { http } from '../config'; // axios instance
import { handleError, logout } from '../helpers/handlers';

/**
 * Return access token if password match
 * @param {string} password
 * @returns
 */
const login = (password) => {
  const config = {
    method: 'post',
    url: '/admin/login',
    headers: {
      'Content-Type': 'application/json',
    },
    data: { password },
  };

  return http(config)
    .then(({ data: { token } }) => {
      if (token) {
        /** store user details and jwt token in local storage
         to keep user logged in between page refreshes */
        localStorage.setItem('account', JSON.stringify({ token }));
      }
      return { token };
    })
    .catch(handleError);
};

export default { login, logout };
