import service from '../../services/account.service';
import router from '../../router';

/* eslint-disable */
const account = JSON.parse(localStorage.getItem('account'));

export default {
  namespaced: true,

  state: account
    ? { status: { loggedIn: true }, account: { ...account } }
    : { status: {}, account: {} },

  actions: {
    login({ commit }, { password }) {
      return service.login(password).then(
        ({ token } = {}) => {
          if (token) {
            commit('loginSuccess', token);
          }
          return !!token;
        },
        (error) => {
          commit('loginFailure', error);
        },
      );
    },
    logout({ commit }) {
      service.logout();
      commit('logout');
      router.push('/login');
    },
  },

  mutations: {
    loginSuccess(state, token) {
      state.status = { loggedIn: true };
      state.account = { token };
    },
    loginFailure(state, error) {
      state.status = { error };
      state.account = {};
    },
    logout(state) {
      state.status = {};
      state.account = {};
    },
  },
};
