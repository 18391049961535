import Vue from 'vue';
import Vuex from 'vuex';

import account from './modules/account.module';
import licenses from './modules/licenses.module';
import invoices from './modules/invoices.module';
import customers from './modules/customers.module';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    account,
    licenses,
    invoices,
    customers,
  },
});
