<template>
  <label for="name" class="me-4 mb-3">
    {{ label }}
    <select
      class="filter-input ms-3"
      ref="select"
      :id="name"
      :name="name"
      autocomplete="off"
      @input="handleDropdown">
      <option value="" selected>{{ placeholder }}</option>
      <option v-for="(option, i) in options" :key="i" :value="option.value">
        {{ option.name }}
      </option>
    </select>
  </label>
</template>

<script>
export default {
  name: 'Dropdown',
  created() {},
  data() {
    return {};
  },
  props: {
    name: String,
    label: String,
    options: Array,
    placeholder: String,
  },
  methods: {
    handleDropdown(e) {
      this.$emit('dropdown', this.name, e.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-input {
  padding: 3px 6px;
  border: solid 2px #c7c7c7;
}
</style>
