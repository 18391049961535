<template>
  <div class="home">
    <div
      v-if="customers.loading || licenses.loading"
      class="position-absolute top-50 start-50 translate-middle">
      <div class="spinner-border text-primary" style="width: 5rem; height: 5rem" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div v-else class="wrapper d-flex">
      <Sidebar :selected="sidebarFilter" @switchFilter="switchFilters" />
      <Board
        ref="board"
        v-if="section === 'customers'"
        :customers="customers.items"
        :dropdowns="dropdowns"
        :title="title"
        @input="searchByName"
        @filter="addFilter"
        @exportToExcel="exportToExcel">
        <ListItems
          v-if="customers.items.length"
          ref="listItems"
          :items="items"
          @mounted="scroll"
          v-bind="tableSettings"
          @showDetails="showDetails" />
      </Board>
      <CustomerDetails
        v-else-if="section === 'customerDetails'"
        v-bind="customer"
        :status="currentFilters.status === 'active' ? 'activo' : 'inactivo'"
        @transaction="setInvoiceType"
        @go-back="goBack" />
    </div>
    <InvoiceEditorModal
      v-if="!customers.loading && !licenses.loading"
      :customer="customer[0]"
      :status="currentFilters.status === 'active' ? 'activo' : 'inactivo'"
      :type="transactionType"
      :licenses="licenses.items"
      :licenseOptions="licenseOptions" />
    <CustomerEditorModal v-if="!customers.loading" :customer="customer[0]" />
    <PasswordEditorModal v-if="!customers.loading" :customer="customer[0]" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

// @ is an alias to /src
import Board from '@/components/Board.vue';
import Sidebar from '@/components/Sidebar.vue';
import ListItems from '@/components/ListItems.vue';
import CustomerDetails from '@/components/CustomerDetails.vue';
import InvoiceEditorModal from '@/components/InvoiceEditorModal.vue';
import CustomerEditorModal from '@/components/CustomerEditorModal.vue';
import PasswordEditorModal from '@/components/PasswordEditorModal.vue';

export default {
  name: 'Customers',
  components: {
    ListItems,
    InvoiceEditorModal,
    PasswordEditorModal,
    CustomerEditorModal,
    CustomerDetails,
    Sidebar,
    Board,
  },
  created() {
    this.getAllLicenses().then(() => {
      this.$log('Licenses', this.licenses);
      this.dropdowns[2].options = this.licenseOptions;
    });
  },
  mounted() {
    document.title = 'License Admin Panel';
    const [section] = window.location.pathname.split('/').reverse();

    this.currentFilters = this.filters[this.getKeyFilter(section)];

    this.getAllCustomers({ filters: this.currentFilters }).then(() => {
      this.$log('Customers', this.customers);
    });

    this.sidebarFilter = this.getKeyFilter(section);

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    this.customerID = urlParams.get('id');

    this.setTitle(section);

    if (this.customerID) this.section = 'customerDetails';

    this.dropdowns[0].options = this.expiryOptions.active;
  },
  data() {
    return {
      section: 'customers',
      title: 'Freemium',
      page: 1,
      customerID: '',
      sidebarFilter: 'DA',
      transactionType: 'RD',
      currentFilters: { status: 'active', demo: true },
      filters: {
        F: { license: 'Freemium' },
        CA: { status: 'active', demo: false, excludeLicense: 'Freemium' },
        CI: { status: 'inactive', demo: false, excludeLicense: 'Freemium' },
        DA: { status: 'active', demo: true, excludeLicense: 'Freemium' },
        DI: { status: 'inactive', demo: true, excludeLicense: 'Freemium' },
      },
      tableSettings: {
        cols: [
          {
            header: 'Fecha de registro',
            prop: 'created',
            style: 'width: 150px; min-width: 150px; text-align: center',
          },
          {
            header: 'Nombre de contacto',
            prop: 'full_name',
            style: 'width: 250px; min-width: 250px',
          },
          {
            header: 'Correo',
            prop: 'email',
            style: 'width: 300px; min-width: 300px',
          },
          {
            header: 'Teléfono',
            prop: 'phone',
            style: 'width: 150px; min-width: 150px; text-align: center',
          },
          {
            header: 'Restaurante',
            prop: 'company',
            style: 'width: 250px; min-width: 250px',
          },
          {
            header: 'Giro',
            prop: 'business_kind',
            style: 'width: 180px; min-width: 180px; text-align: center',
          },
          {
            header: 'Plan',
            prop: 'license',
            style: 'width: 200px; min-width: 200px; text-align: center',
          },
          {
            header: 'Fecha de Expiración',
            prop: 'expiry',
            style: 'width: 200px; min-width: 200px; text-align: center',
          },
          {
            header: 'Sucursales',
            prop: 'sucursales',
            style: 'width: 100px; min-width: 100px; text-align: center',
          },
        ],
      },
      dropdowns: [
        {
          name: 'expiry',
          label: 'Expiración',
          placeholder: 'Todos',
          options: [],
        },
        {
          name: 'sucursales',
          label: '# Sucursales',
          placeholder: 'Todos',
          options: [
            {
              name: 'Sin sucursales',
              value: 0,
            },
            {
              name: '1 Sucursal',
              value: 1,
            },
            {
              name: '2 o Más Sucursales',
              value: 2,
            },
          ],
        },
        {
          name: 'license',
          label: 'Licencia',
          placeholder: 'Todas',
          options: [],
        },
      ],
      expiryOptions: {
        active: [
          {
            name: 'Vence hoy',
            value: this.$moment().add(1, 'days').format('YYYY-MM-DD'),
          },
          {
            name: 'Vence esta semana',
            value: this.$moment().endOf('week').add(1, 'days').format('YYYY-MM-DD'),
          },
          {
            name: 'Vence este mes',
            value: this.$moment().endOf('month').add(1, 'days').format('YYYY-MM-DD'),
          },
          {
            name: 'Vence el próximo mes',
            value: this.$moment().add(1, 'month').endOf('month').format('YYYY-MM-DD'),
          },
          {
            name: 'Vence dentro de mucho',
            value: this.$moment().add(3, 'years').endOf('year').format('YYYY-MM-DD'),
          },
        ],
        inactive: [
          {
            name: 'Venció hoy',
            value: this.$moment().format('YYYY-MM-DD'),
          },
          {
            name: 'Venció esta semana',
            value: this.$moment().startOf('week').format('YYYY-MM-DD'),
          },
          {
            name: 'Venció este mes',
            value: this.$moment().startOf('month').format('YYYY-MM-DD'),
          },
          {
            name: 'Venció el mes pasado',
            value: this.$moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
          },
          {
            name: 'Venció hace mucho',
            value: this.$moment().subtract(5, 'years').startOf('year').format('YYYY-MM-DD'),
          },
        ],
      },
    };
  },
  computed: {
    ...mapState({
      customers: (state) => state.customers.all,
      licenses: (state) => state.licenses.all,
    }),
    customer() {
      if (this.customerID && !this.customers.loading)
        return this.customers.items.filter((c) => this.customerID === c.id);

      return [{}];
    },
    licenseOptions() {
      const list = [];
      this.licenses.items.forEach((item) => {
        if (!list.includes(item.name) && item.amount > 0) {
          list.push(item.name);
        }
      });
      return list.map((l) => ({ name: l, value: l }));
    },
    items() {
      return this.customers.items.map((item) => {
        const created = this.$moment(item.created).format('DD-MM-YYYY');
        let expiry = this.$moment(item.expiry).format('DD-MM-YYYY');
        expiry = expiry !== 'Invalid date' ? expiry : 'N/A';
        const license = item.license ? item.license : 'N/A';
        const sucursales = item.sucursales ? item.sucursales : 0;
        const fullName = `${item.first_name} ${item.last_name}`;

        return { ...item, full_name: fullName, license, created, expiry, sucursales };
      });
    },
  },
  methods: {
    ...mapActions('customers', {
      getAllCustomers: 'getAll',
    }),
    ...mapActions('licenses', {
      getAllLicenses: 'getAll',
    }),
    showDetails(id) {
      this.$router.push(`${window.location.pathname}?id=${id}`)
      this.customerID = id;
      this.section = 'customerDetails';
    },
    scroll() {
      const table = this.$refs.listItems.$refs.items;

      table.onscroll = () => {
        const { scrollTop, offsetHeight, scrollHeight } = table;

        const scrollIsBottom = Math.ceil(scrollTop + offsetHeight) >= scrollHeight - 1;

        if (scrollIsBottom) {
          if (this.currentFilters.sucursales) {
            if (this.customers.info?.next) {
              this.page += 1;
              this.getAllCustomers({ page: this.page, filters: this.currentFilters });
            } else {
              this.page = 1;
            }
          } else {
            const page = Math.ceil(this.customers.items.length / 20 + 1);
            // If customers amount in state === limit for page (20) : get next page
            if (this.customers.items.length === (page - 1) * 20)
              this.getAllCustomers({ page, filters: this.currentFilters });
          }
        }
      };
    },
    setTitle(section) {
      const titles = {
        freemium: 'Freemium',
        active: 'Cuentas Activas',
        inactive: 'Cuentas Inactivas',
        'demo-active': 'Demos Activos',
        'demo-inactive': 'Demos Inactivos',
      }

      this.title = titles[section]
    },
    goBack() {
      this.customerID = null;
      this.section = 'customers';
      this.$router.push(window.location.pathname);
    },
    setInvoiceType(t) {
      this.transactionType = t;
    },
    searchByName(name) {
      this.currentFilters.name = name;
      this.getAllCustomers({ filters: this.currentFilters });
    },
    switchFilters(key) {
      this.customerID = null;
      const [section] = window.location.pathname.split('/').reverse();
      this.setTitle(section);
      this.currentFilters = this.filters[key];
      this.sidebarFilter = key;
      this.section = 'customers';
      this.resetBoard();
      this.getAllCustomers({ filters: this.currentFilters });
    },
    addFilter(filter, value) {
      if (filter === 'expiry') {
        if (value) {
          const unitOfTime = this.$moment(value).fromNow(true).split(' ')[1];

          const exp = this.$moment(value).fromNow().split(' ')[2];

          if (exp !== 'ago') {
            this.currentFilters.start = this.$moment().format('YYYY-MM-DD');
            this.currentFilters.end = value;
          } else {
            this.currentFilters.end = this.$moment().format('YYYY-MM-DD');
            this.currentFilters.start = value;
          }

          // Already expired
          if (exp === 'ago' && (unitOfTime === 'month' || unitOfTime === 'months')) {
            this.currentFilters.start = this.$moment(value).startOf('month').format('YYYY-MM-DD');
            this.currentFilters.end = this.$moment(value).endOf('month').format('YYYY-MM-DD');
          } else if (exp === 'ago' && unitOfTime === 'years') {
            this.currentFilters.start = this.$moment(value).format('YYYY-MM-DD');
            this.currentFilters.end = this.$moment()
              .subtract(1, 'months')
              .startOf('month')
              .format('YYYY-MM-DD');
          }
          // Will expiry
          if (exp !== 'ago' && (unitOfTime === 'month' || unitOfTime === 'months')) {
            this.currentFilters.start = this.$moment(value).startOf('month').format('YYYY-MM-DD');
            this.currentFilters.end = this.$moment(value).format('YYYY-MM-DD');
          } else if (exp !== 'ago' && unitOfTime === 'years') {
            this.currentFilters.start = this.$moment()
              .add(2, 'months')
              .startOf('month')
              .format('YYYY-MM-DD');
            this.currentFilters.end = this.$moment(value).format('YYYY-MM-DD');
          }
        } else {
          delete this.currentFilters.start;
          delete this.currentFilters.end;
        }
      } else {
        this.currentFilters[filter] = value;
      }

      this.getAllCustomers({ filters: this.currentFilters });
    },
    exportToExcel() {},
    resetBoard() {
      if (this.currentFilters.status === 'active') {
        this.dropdowns[0].options = this.expiryOptions.active;
      } else if (this.currentFilters.status === 'inactive') {
        this.dropdowns[0].options = this.expiryOptions.inactive;
      }

      const filters = Object.fromEntries(
        Object.entries(this.filters).map(([k, v]) => {
          const o = {
            status: v.status,
            demo: v.demo,
          };

          if (v.license) o.license = v.license
          if (v.excludeLicense) o.excludeLicense = v.excludeLicense

          return [k, o];
        }),
      );

      this.filters = filters;
    },
    getKeyFilter(section) {
      const keys = {
        freemium: 'F',
        active: 'CA',
        inactive: 'CI',
        'demo-active': 'DA',
        'demo-inactive': 'DI',
      }

      return keys[section];
    },
  },
};
</script>
<style>
.home,
.wrapper {
  height: 100%;
}
</style>
