import { http, log } from '../config'; // axios instance
import { handleError } from '../helpers/handlers';
import authHeader from '../helpers/authHeader';

/**
 * Get all licenses
 * @returns
 */
const getAll = () => {
  const config = {
    method: 'get',
    url: `/v1/plans`,
    headers: authHeader(),
  };

  log('Getting licenses...');

  return http(config)
    .then(({ data }) => data)
    .catch(handleError);
};

export default { getAll };
