import { http, log } from '../config'; // axios instance
import { handleError } from '../helpers/handlers';
import authHeader from '../helpers/authHeader';

/**
 * Get all Invoices
 * @param {number} page page of records
 * @returns
 */
const getAll = (id) => {
  const config = {
    method: 'get',
    url: `/v1/customers/${id}/invoices`,
    headers: authHeader(),
  };

  log('Getting Invoices...');
  log(config.url);

  return http(config)
    .then(({ data }) => data)
    .catch(handleError);
};

// /**
//  * Create transaction for a customer
//  * @param {string} id customer id
//  * @returns
//  */
// const create = (id, data) => {
//   const config = {
//     method: 'post',
//     url: `/v1/customers/${id}/menu/invoices`,
//     headers: { ...authHeader(), 'Content-Type': 'application/json' },
//     data,
//   };

//   log('New transaction for customer', id);
//   log('Payload', data);
//   log(config.url);

//   return http(config).catch(handleError);
// };

const getAccountSubs = (id) => {
  const config = {
    method: 'get',
    url: `/v1/customers/${id}/subscriptions/menu`,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };

  return http(config).catch(handleError);
};

const extendDemo = (id, subsId, data) => {
  const config = {
    method: 'post',
    url: `/v1/customers/${id}/subscriptions/${subsId}/extend`,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    data,
  };

  log('New transaction for customer', id);
  log('Payload', data);
  log(config.url);

  return http(config).catch(handleError);
};

const newSubscription = (id, data) => {
  const config = {
    method: 'put',
    url: `/v1/customers/${id}/subscriptions`,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    data,
  };

  log('New transaction for customer', id);
  log('Payload', data);
  log(config.url);

  return http(config).catch(handleError);
};

const activateLicenses = (id, subsId, data) => {
  console.log(activateLicenses, data);
  const config = {
    method: 'post',
    url: `/v1/customers/${id}/subscriptions/${subsId}/activate`,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    data,
  };

  log('New transaction for customer', id);
  log('Payload', data);
  log(config.url);

  return http(config).catch(handleError);
};

const revertSubscription = (id, subsId) => {
  const config = {
    method: 'delete',
    url: `/v1/customers/${id}/subscriptions/${subsId}/revert`,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };

  log(config.url);

  return http(config).catch(handleError);
};

/**
 * Remove last transaction of subscription
 * @param {number} id transaction id
 * @param {string} customerId customer id
 * @returns
 */
const remove = (id, customerId) => {
  const config = {
    method: 'delete',
    url: `/v1/customers/${customerId}/subscriptions/${id}`,
    headers: authHeader(),
  };

  log('Deleting last transaction of subscription', id, 'of customer', customerId);
  log(config.url);

  return http(config).catch(handleError);
};

export default {
  getAll,
  // create,
  remove,
  extendDemo,
  getAccountSubs,
  newSubscription,
  activateLicenses,
  revertSubscription,
};
