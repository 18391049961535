import service from '../../services/customers.service';
import { log } from '../../config';

export default {
  namespaced: true,

  state: {
    all: {},
  },

  actions: {
    getAll({ commit }, { page, filters } = {}) {
      commit('getAllRequest');

      return service.getAll(page, filters).then(
        ({ info, results }) => {
          log(info.count, 'Customers');
          commit('getAllSuccess', { customers: results, info, page });
        },
        (error) => commit('getAllFailure', error),
      );
    },
    getOne({ commit }, id) {
      commit('getOneRequest');

      return service.getOne(id).then(
        (customer) => {
          commit('getOneSuccess', customer);
          commit('updateSuccess', { id, data: customer });
          return customer;
        },
        (error) => commit('getOneFailure', error),
      );
    },
    update({ commit }, { id, data }) {
      commit('updateRequest');

      return service.update(id, data).then(
        () => commit('updateSuccess', { id, data }),
        (error) => commit('updateFailure', error),
      );
    },
    remove({ dispatch }, id) {
      return service.remove(id).then(() => dispatch('getAll'));
    },
    changePassword({ commit }, { id, data }) {
      return service.changePassword(id, data).then(
        () => {},
        (error) => commit('updateFailure', error),
      );
    },
  },

  mutations: {
    // Get All
    getAllRequest(state) {
      state.all.loading = true;
    },
    getAllSuccess(state, { customers, info, page }) {
      if (!page) {
        state.all = { items: customers, info, page: 1 };
      } else if (page > state.all.page) {
        state.all.items.push(...customers);
        state.all.info = info;
        state.all.page = page;
      }

      state.all.loading = false;
    },
    getAllFailure(state, error) {
      state.all = { error };
    },

    // Get One
    getOneRequest(state) {
      state.all.single = { loading: true };
    },
    getOneSuccess(state, customer) {
      state.all.single.data = customer;
      state.all.single.loading = false;
    },
    getOneFailure(state, error) {
      state.all.single = { error };
    },

    // Update
    updateRequest(state) {
      state.all.single = { loading: true };
    },
    updateSuccess(state, { id, data }) {
      state.all.items = state.all.items.map((item) => {
        if (item.id === id) {
          return { ...item, ...data };
        }
        return item;
      });

      state.all.single.loading = false;
    },
    updateFailure(state, error) {
      state.all = { error };
    },
  },
};
