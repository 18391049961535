import axios from 'axios';

const devEnv = process.env.NODE_ENV !== 'production';

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

export const http = axios;

export const log = (...args) => {
  if (devEnv) {
    console.log(...args);
  }
};

export default {
  axios,
};
