import { http, log } from '../config'; // axios instance
import { handleError } from '../helpers/handlers';
import authHeader from '../helpers/authHeader';

/**
 * Get all customers
 * @param {number} page page of records
 * @returns
 */
const getAll = (page = 1, filters = {}) => {
  const config = {
    method: 'get',
    url: `/v1/customers?page=${page}`,
    headers: authHeader(),
  };

  Object.entries(filters).forEach(([k, v]) => {
    config.url = `${config.url}&${k}=${v}`;
  });

  log('Getting customers...');
  log(config.url);

  return http(config)
    .then(({ data }) => data)
    .catch(handleError);
};

/**
 * Get single customer
 * @param {string} id customer id
 * @returns
 */
const getOne = (id) => {
  const config = {
    method: 'get',
    url: `/v1/customers/${id}`,
    headers: authHeader(),
  };

  log('Getting customer', id);

  return http(config)
    .then(({ data }) => data)
    .catch(handleError);
};

/**
 * Update customer
 * @param {string} id customer id
 * @returns
 */
const update = (id, data) => {
  const config = {
    method: 'put',
    url: `/v1/customers/${id}`,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    data,
  };

  log('Updating customer', id);

  return http(config).catch(handleError);
};

const remove = (id) => {
  const config = {
    method: 'delete',
    url: `/v1/customers/${id}`,
    headers: authHeader(),
  };

  log('Deleting customer', id);

  return http(config).catch(handleError);
};

const changePassword = (id, data) => {
  const config = {
    method: 'put',
    url: `/v1/customers/${id}/reset-password`,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    data,
  };

  log('Updating customer', id);

  return http(config).catch(handleError);
};

export default { getAll, getOne, update, remove, changePassword };
