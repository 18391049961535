import { log } from '../config';

export const logout = () => {
  // remove account from local storage to log user out
  localStorage.removeItem('account');
};

export const handleError = (error) => {
  const { status, data } = error.response;

  if (
    (status === 401 || status === 403) &&
    (data.error.message === 'Token expired' || data.error.message === 'Wrong token')
  ) {
    // auto logout if 401 or 403 response returned from api
    logout();
    // eslint-disable-next-line
    if (location.pathname !== '/login') location.pathname = '/login';
  }

  log(status, data.status, data.message);
};

export default {
  handleError,
};
