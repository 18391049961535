import service from '../../services/invoices.service';

export default {
  namespaced: true,

  state: {
    all: {},
  },

  actions: {
    getAll({ commit }, id) {
      commit('getAllRequest');

      return service.getAll(id).then(
        (invoices) => {
          commit('getAllSuccess', invoices);
        },
        (error) => commit('getAllFailure', error),
      );
    },
    getAccountSubs({ commit }, id) {
      return service.getAccountSubs(id).catch((error) => commit('getAllFailure', error));
    },
    create({ commit, dispatch }, { id, data }) {
      commit('createRequest');

      return service.create(id, data).then(
        () => dispatch('getAll', id),
        (error) => commit('createFailure', error),
      );
    },
    extendDemo({ commit }, { id, subsId, data }) {
      commit('createRequest');

      return service.extendDemo(id, subsId, data).then(
        () => {},
        (error) => commit('createFailure', error),
      );
    },
    newSubscription({ commit }, { id, data }) {
      return service.newSubscription(id, data).then(
        () => {},
        (error) => commit('createFailure', error),
      );
    },
    activateLicenses({ commit }, { id, subsId, data }) {
      return service.activateLicenses(id, subsId, data).then(
        () => {},
        (error) => commit('createFailure', error),
      );
    },
    revertSubscription({ commit }, { id, subsId }) {
      return service.revertSubscription(id, subsId).then(
        () => {},
        (error) => commit('createFailure', error),
      );
    },
    remove({ commit }, { id, customerId }) {
      return service.remove(id, customerId).then(
        () => commit('deleteSuccess', id),
        (error) => commit('createFailure', error),
      );
    },
  },

  mutations: {
    // Get All
    getAllRequest(state) {
      state.all.loading = true;
    },
    getAllSuccess(state, invoices) {
      state.all = { items: invoices, loading: false };
    },
    getAllFailure(state, error) {
      console.log(error);
      state.all = { error };
    },

    // create
    createRequest(state) {
      state.all = { loading: true };
    },
    createFailure(state, error) {
      state.all = { error };
    },

    // delete
    deleteSuccess(state, id) {
      state.all.items = state.all.items.filter((item) => item.id !== id);
    },
    deleteFailure(state, error) {
      state.all = { error };
    },
  },
};
