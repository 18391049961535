<template>
  <table cellspacing="0" cellpadding="0" border="0" width="100%" height="100%">
    <tr>
      <td>
        <table width="100%" class="table table-bordered mb-0">
          <thead>
            <tr>
              <th
                scope="col"
                v-for="(col, i) in cols"
                :key="i"
                style="cursor: pointer"
                @click="sort(col.prop)"
                :style="col.style">
                <span>{{ col.header }}</span>
              </th>
            </tr>
          </thead>
        </table>
      </td>
    </tr>
    <tr height="100%">
      <td>
        <div ref="items" class="scrollbar-hidden" style="height: 100%; overflow-y: scroll">
          <table width="100%" class="table table-striped table-hover table-bordered">
            <tbody>
              <tr
                class="row-item"
                v-for="(item, i) in list"
                :key="i"
                @click="$emit('showDetails', item.id)">
                <td v-for="(col, i) in cols" :key="i" :style="col.style">
                  <span>{{
                    col.prop === 'variants' && !item[col.prop].length
                      ? 'N/A'
                      : col.prop === 'template' && !item[col.prop]
                      ? 'N/A'
                      : item[col.prop]
                  }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </td>
    </tr>
  </table>
</template>

<script>
export default {
  name: 'ListItems',
  created() {},
  mounted() {
    this.$emit('mounted');
  },
  data() {
    return {
      sortBy: '',
      sortReverse: false,
    };
  },
  props: {
    items: Array,
    cols: Array,
  },
  computed: {
    list() {
      const list = this.items;
      if (list.length) {
        if (typeof list[0][this.sortBy] === 'string') {
          return list.sort((a, b) => {
            if (this.sortReverse) {
              return b[this.sortBy].localeCompare(a[this.sortBy]);
            }

            return a[this.sortBy].localeCompare(b[this.sortBy]);
          });
        }

        if (typeof list[0][this.sortBy] === 'number') {
          return list.sort((a, b) => {
            if (this.sortReverse) {
              return b[this.sortBy] - a[this.sortBy];
            }
            return a[this.sortBy] - b[this.sortBy];
          });
        }
        return list;
      }

      return [];
    },
  },
  methods: {
    sort(prop) {
      this.sortBy = prop;
      this.sortReverse = !this.sortReverse;
    },
  },
};
</script>

<style lang="scss" scoped>
.row-item {
  cursor: pointer;
}
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}
</style>
