<template>
  <div class="board d-flex flex-column overflow-hidden w-100 p-5">
    <div class="board-header">
      <div class="board-title pt-4 pb-5 mb-5">
        <span class="fs-2">{{title}}</span>
      </div>
      <div class="board-controls d-flex justify-content-between w-100 pt-5">
        <div class="board-filters">
          <label for="search" class="me-4 mb-3"
            >Buscar<input
              class="filter-input ms-3"
              name="search"
              type="text"
              v-model="name"
              placeholder="Nombre / Restaurante"
              @input="handleInput"
          /></label>
          <Dropdown
            v-for="(drop, i) in dropdowns"
            ref="dropdowns"
            :key="i"
            v-bind="drop"
            @dropdown="handleDropdown" />
        </div>
        <!-- <div class="board-actions">
          <button class="btn btn-secondary btn-sm" @click="$emit('exportToExcel')">
            <i class="icon-export"></i> Exportar
          </button>
        </div> -->
      </div>
    </div>
    <div class="board-content d-flex flex-column flex-grow-1">
      <div class="board-table my-5" :style="'overflow-x: scroll'">
        <slot></slot>
      </div>
      <div class="board-footer text-end">
        <p class="fs-3">Total: {{ customers.length }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import Dropdown from '@/components/Dropdown.vue';

export default {
  name: 'Board',
  components: {
    Dropdown,
  },
  created() {},
  data() {
    return {
      name: '',
    };
  },
  props: {
    title: String,
    customers: Array,
    dropdowns: Array,
  },
  methods: {
    handleDropdown(filter, value) {
      this.$emit('filter', filter, value);
    },
    handleInput(e) {
      this.$emit('input', e.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.board {
  &-table {
    height: 85%;
  }
}
.filter-input {
  padding: 3px 6px;
  border: solid 2px #c7c7c7;
}
</style>
