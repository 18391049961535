/** Styles */
import 'normalize.css';
import 'bootstrap/scss/bootstrap.scss';
import 'bootstrap/dist/js/bootstrap.min';
import './assets/icons/font/style.css';
import './assets/fonts/style.css';

/** Vue */
import Vue from 'vue';
import moment from 'moment';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import { http, log } from './config';

Vue.config.productionTip = false;
Vue.prototype.$http = http;
Vue.prototype.$moment = moment;
Vue.prototype.$log = log;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
