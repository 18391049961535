<template>
  <div class="sidebar d-flex flex-column border-end border-2">
    <div class="sidebar-branding px-5 py-5">
      <img src="@/assets/images/mindfood-solutions.png" alt="mindfood logo" />
    </div>
    <div class="sidebar-options d-flex flex-column h-100 py-4">
      <div class="sidebar-option mb-5">
        <b class="d-block border-bottom border-2 py-4 px-3">Cuentas</b>
        <div
          class="sidebar-filter border-bottom border-2 py-4 px-5"
          :class="selected === 'F' ? 'sidebar-filter-active' : ''"
          @click="switchFilter('F')">
          Freemium
        </div>
      </div>
      <div class="sidebar-option mb-5">
        <b class="d-block border-bottom border-2 py-4 px-3">Demos</b>
        <div
          class="sidebar-filter border-bottom border-2 py-4 px-5"
          :class="selected === 'DA' ? 'sidebar-filter-active' : ''"
          @click="switchFilter('DA')">
          Demos activos
        </div>
        <div
          class="sidebar-filter border-bottom border-2 py-4 px-5"
          :class="selected === 'DI' ? 'sidebar-filter-active' : ''"
          @click="switchFilter('DI')">
          Demos inactivos
        </div>
      </div>
      <div class="sidebar-option">
        <b class="d-block border-bottom border-2 py-4 px-3">Clientes</b>
        <div
          class="sidebar-filter border-bottom border-2 py-4 px-5"
          :class="selected === 'CA' ? 'sidebar-filter-active' : ''"
          @click="switchFilter('CA')">
          Clientes activos
        </div>
        <div
          class="sidebar-filter border-bottom border-2 py-4 px-5"
          :class="selected === 'CI' ? 'sidebar-filter-active' : ''"
          @click="switchFilter('CI')">
          Clientes inactivos
        </div>
      </div>
    </div>
    <button class="btn btn-secondary border-0 py-5" @click="logout">Cerrar Sesión</button>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'Sidebar',
  created() {},
  data() {
    return {
      sections: {
        F: 'freemium',
        CA: 'active',
        CI: 'inactive',
        DA: 'demo-active',
        DI: 'demo-inactive',
      }
    };
  },
  props: {
    selected: String,
  },
  methods: {
    ...mapActions('account', ['logout']),
    switchFilter(filter) {

      if (!window.location.pathname.includes(this.sections[filter])) this.$router.push(this.sections[filter]);
      this.$emit('switchFilter', filter);
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  height: 100%;
  max-width: 200px;
  &-options {
    background: #f7f9fa;
  }
  &-filter {
    cursor: pointer;
    color: #55626c;
    &:hover,
    &-active {
      background-color: $primary-light;
      color: $light;
    }
  }
}
</style>
